import React from 'react'
import Object from '../../../assets/images/objects.png'
import ArrowRight from '../../../assets/images/arrow-right.svg'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyConnectStoreImage = ({ src, alt }) => {
  return (
    <LazyLoadImage
      alt={alt}
      src={src}
      effect="blur"
    className='w-[264px] h-[300px] px-[5px] mt-[100px] md:mt-[10px] mb-[40px] lg:mt-0 lg:mb-0'
    />
  );
};

const ConnectStore = () => {
  return (
    <div className='flex flex-col lg:flex-row justify-center items-center gap-[150px] px-[20px] py-[50px] lg:gap-[100px] bg-basegreen w-full h-[1300px] lg:h-[615px] mt-[80px]'>
<div className='flex flex-col gap-[100px] lg:gap-[65px] items-center lg:items-start w-full px-[10px]  lg:w-[600px] h-[300px] lg:p-3'>
<div className='flex flex-col gap-5'>
<h2 className='text-white font-[400] text-[32px] text-center lg:text-left leading-[40px] -tracking-[3%]' data-aos="fade-right">Create or Connect your existing eCommerce store</h2>
<p className='text-textgreen font-[400] text-[16px] text-center lg:text-left leading-[24px] -tracking-[3%]' data-aos="fade-right">Connect your existing systems seamlessly with our developer-friendly APIs. Customize your logistics experience, automate workflows, and gain even deeper insights into your deliveries.</p>
</div>
<div className=''>
<a href='https://fraser.stoplight.io/docs/central/cbb186dc155a8-merchant-management' target="_blank" rel="noreferrer" className='flex justify-end text-textgreen text-center lg:text-left font-[400] text-[16px] leading-[24px] -tracking-[3%] cursor-pointer' data-aos="fade-right">View our API Documentation
<img src={ArrowRight} alt='Navigate icon to API Documentation' className='w-[24px] h-[24px] cursor-pointer'/>
</a>
</div>
</div>
<div data-aos="zoom-in">
<LazyConnectStoreImage src={Object} alt='An Object' />
</div>
    </div>
  )
}

export default ConnectStore