import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useBlogClick from '../../../custom-hooks/useBlogClick';

const LazyBlogImage = ({ src, alt }) => {
    return (
      <LazyLoadImage
        alt={alt}
        src={src}
        className="w-full h-[100%] rounded-[8px]"
        effect="blur"
      />
    );
  };
const ProductBox = ({ image, title, date, id }) => {
   let {handleBlogClick} = useBlogClick(id);
  return (
    <>
        <div
        className="w-[250px] h-[300px] flex flex-col gap-5 cursor-pointer mt-[15px] mb-[80px]"
        onClick={handleBlogClick}
        data-aos="zoom-in"
      >
        <LazyBlogImage src={image} alt={title} />
        <h2 className="text-primary text-[21px] font-[400] leading-[32px] -tracking-[3%] ">
          {title}
        </h2>
        <p className="text-secondary text-[14px] font-[400] leading-[21px] -tracking-[3%]">
          {date}
        </p>
      </div>
    </>
  )
}

export default ProductBox