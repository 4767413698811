import React from 'react';
import { FaBagShopping } from 'react-icons/fa6';
import Avatar from './avatar';
import { FaSearch } from 'react-icons/fa';

const StoreHeader = ({ storeData, onClickCart, cartItems, onSearch }) => {
	const storeHeaderColor = storeData?.settings?.storeHeaderColor || '#ffffff';
	const categories = storeData?.categories || [];
	const cartSize = cartItems?.length || 0;

	return (
		<div
			className='flex px-4 py-4 z-40 w-full justify-between items-center'
			style={{ backgroundColor: storeHeaderColor }}>
			{/* Left Section: Logo and Store Name */}
			<div className='flex items-center space-x-4'>
				<Avatar
					firstName={storeData?.storeName}
					imageSrc={storeData?.settings?.logo}
					size={32}
				/>
				<div>
					<h1 className='text-lg font-medium leading-tight tracking-tight text-gray-700'>
						{storeData?.storeName}
					</h1>
					{/* <p className='text-sm text-gray-500'>
						{storeData?.location?.address}
					</p> */}
				</div>
			</div>

			{/* Center Section: Store Categories */}
			<div className='hidden md:flex space-x-8'>
				{categories.map((category, index) => (
					<a
						key={index}
						href={`/category/${category.slug}`}
						className='text-gray-700 hover:text-gray-900'>
						{category.name}
					</a>
				))}
			</div>

			{/* Right Section: Search Icon and Cart */}
			<div className='flex items-center space-x-6'>
				{/* Search */}
				<div
					className='relative cursor-pointer'
					onClick={onSearch}>
					<FaSearch className='text-gray-600 w-5 h-5' />
				</div>

				{/* Cart */}
				<div
					className='relative space-x-4 bg-gray-100 flex items-center justify-center cursor-pointer py-2 px-4 rounded-full text-primary'
					onClick={onClickCart}>
					<FaBagShopping className='w-4 h-4' />{' '}
					<div className='space-x-1 flex items-center'>
						<p>Bag</p>
						<p>({cartSize})</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StoreHeader;
