import { api } from './api_helper';

class ApiService {
	async getStore(identifier) {
		try {
			const response = await api.get(`/customer/store/${identifier}`, {
				withCredentials: true,
			});
			return response.data.data;
		} catch (error) {
			console.error(error);
			this.handleApiError('Error fetching store', error);
		}
	}

	// Error handler
	handleApiError(message, error) {
		console.error(
			`${message}:`,
			error.response?.data?.message || error.message,
		);
		throw new Error(`${message}. Please try again later.`);
	}
}

export default ApiService;
