import React, { useEffect, useRef } from 'react';

const Dropdown = ({ actions, onClose, position, width }) => {
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);

	return (
		<div
			ref={dropdownRef}
			className={`absolute ${position} bg-white border border-gray-200 rounded-md shadow-lg z-30`}
			style={{ minWidth: '40px' }}
			onClick={(e) => e.stopPropagation()}>
			<div className='flex flex-col w-full items-center gap-2 p-2'>
				{actions?.map((action, index) => (
					<button
						key={index}
						className={`${width} bg-bgNeutral rounded-[4px] p-3 text-[500] text-primary text-[16px] leading-[18px] -tracking-[3%] text-left`}
						onClick={() => {
							action.onClick();
							onClose();
						}}>
						{action.label}
					</button>
				))}
			</div>
		</div>
	);
};

export default Dropdown;
