export const paths = {
	HOME: '/',
	ABOUT: '/about',
	COMMUNITY: '/community',
	BLOG: '/blog',
	BLOG_DETAIL: '/blog/:id',
	DASHBOARD: '/dashboard',
	PRIVACY_POLICY: '/privacy-policy',
	ADMIN: '/admin',
	EVENTS: '/events',

	// shooping
	STORE: '/:storeIdentifier',
};
