// ProductCard.js
import React from 'react';
import { FaEllipsisV } from 'react-icons/fa'; // Ensure you import the icon
import Dropdown from './dropdown';

const ProductCard = ({
	product,
	onCardClick,
	onAddToCart,
	onActionClick,
	openDropdownId,
	handleActionClick,
	closeDropdown,
}) => {
	return (
		<div
			key={product.id}
			className='relative rounded-xl overflow-hidden'
			onClick={() => onCardClick(product)}>
			{product.images && (
				<div className='relative'>
					<img
						src={product.images[0]}
						alt={product.name}
						className='w-full h-48 object-cover rounded-xl'
					/>

					<div className='absolute top-3 right-4'>
						<div className='relative'>
							<button
								className='text-white p-1 rounded-full bg-gray-800 bg-opacity-50'
								onClick={(e) => handleActionClick(product, e)}>
								<FaEllipsisV />
							</button>
						</div>

						{openDropdownId === product.id && (
							<Dropdown
								actions={onActionClick(product)}
								onClose={closeDropdown}
								position='right-0 mt-2'
							/>
						)}
					</div>
				</div>
			)}
			<div className='py-4'>
				<h2 className='font-semibold'>{product.name}</h2>
				<div className='flex justify-between items-center'>
					<span className='text-[14px] font-light text-secondary'>
						NGN {product.unitPrice.toLocaleString()}
					</span>
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
