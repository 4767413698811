import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI } from '../api/api_context';
import StoreFront from './StoreFront/StoreFront';
import { CircularProgress, Box } from '@mui/material';

const StoreContainer = () => {
	const apiService = useAPI();
	const { storeIdentifier } = useParams();
	const [storeData, setStoreData] = useState(null);
	const [loading, setLoading] = useState(true);

	const getStoreData = useCallback(async () => {
		setLoading(true);
		try {
			const response = await apiService.getStore(storeIdentifier);
			setStoreData(response.store);
		} catch (error) {
			console.error('Error fetching store data:', error);
		} finally {
			setLoading(false);
		}
	}, [apiService, storeIdentifier]);

	useEffect(() => {
		getStoreData();
	}, [getStoreData]);

	if (loading) {
		return (
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				height='100vh'>
				<CircularProgress />
			</Box>
		);
	}

	if (!storeData || !storeData.storeName) {
		return (
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				height='100vh'>
				<p>Error: Store data could not be loaded.</p>
			</Box>
		);
	}

	return <StoreFront storeData={storeData} />;
};

export default StoreContainer;
