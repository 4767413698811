import React from 'react';
import Order from '../../../assets/images/order.png';
import Share from '../../../assets/images/share.png';
import ArrowRight from '../../../assets/images/arrow-right.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import useBgLoaded from '../../../custom-hooks/useBgLoaded';

const LazyShareLocationImage = ({ src, alt }) => {
	return (
		<LazyLoadImage
			alt={alt}
			height={54}
			src={src}
			width={300}
			effect='blur'
		/>
	);
};
const LazyOrderImage = ({ src, alt }) => {
	return (
		<LazyLoadImage
			alt={alt}
			height={400}
			src={src}
			width={330}
			effect='blur'
		/>
	);
};

const Header = () => {
	const { ref, inView, bgLoaded, setBgLoaded } = useBgLoaded();

	if (inView && !bgLoaded) {
		setBgLoaded(true);
	}
	return (
		<>
			<div className='flex flex-col lg:flex-row justify-center items-center w-full pt-[150px] lg:pt-[90px] bg-neutral overflow-hidden'>
				{/* First container */}
				<div className='w-full lg:w-[50%] flex flex-col items-start gap-5  py-2 lg:py-[60px] px-[20px] lg:px-[100px]'>
					<h1
						className='text-primary text-[32px] leading-[40px] lg:text-[46px] lg:leading-[57px] font-[400] -tracking-[3%] xl:w-[500px]'
						data-aos='fade-right'>
						Boost operational efficiency with automated deliveries.
					</h1>
					<p
						className='text-secondary text-[16px] leading-[24px] lg:text-[18px] lg:leading-[27px] font-[400] -tracking-[3%]'
						data-aos='fade-right'>
						Ridefraser helps businesses in Africa manage deliveries,
						anywhere, anytime.
					</p>
					<div
						className='flex flex-col items-start lg:flex-row lg:items-center gap-5 lg:gap-4 mt-[40px] mb-[20px] w-full'
						data-aos='fade-right'>
						<a
							href='https://www.merchant.ridefraser.com/signin'
							target='_blank'
							rel='noreferrer'
							className='flex justify-center items-center gap-3 py-[16px] px-[50px] w-full md:w-[230px] h-[56px] bg-basegreen rounded-[30px] cursor-pointer'>
							<span className='text-white text-[16px] leading-[24px] font-[400] -tracking-[3%]'>
								Get Started
							</span>
							<img
								src={ArrowRight}
								alt='Get started button'
								className='w-[24px] h-[24px]'
							/>
						</a>
						<a
							href='https://courier.ridefraser.com/signin'
							target='_blank'
							rel='noreferrer'
							className='cursor-pointer text-basegreen text-[16px] leading-[18px] font-[400] -tracking-[3%]'>
							or signup as Courier
						</a>
					</div>
					<div className='flex flex-col items-start gap-5 mt-[50px] lg:mt-[100px] mb-[20px]'>
						<p className='text-secondary text-[16px] leading-[24px] font-[400] -tracking-[3%]'>
							Trusted by
						</p>
						<div className='flex gap-2'>
							<div className='flex items-center justify-center h-[34px] border border-basegreen rounded-[8px] p-[10px]'>
								<span className='text-[12px] leading-[14px] font-[400] -tracking-[3%]'>
									G & F
								</span>
							</div>
							<div className='flex items-center justify-center h-[34px] border border-basegreen rounded-[8px] p-[10px]'>
								<span className='text-[12px] leading-[14px] font-[400] -tracking-[3%]'>
									G & F
								</span>
							</div>
						</div>
					</div>
				</div>
				{/* Second container */}
				<div
					ref={ref}
					className={`w-full lg:w-[50%] ${
						bgLoaded ? 'bg-header-image' : ''
					} bg-contain lg:bg-cover bg-top lg:bg-center bg-no-repeat py-2 `}>
					<div className='pt-[10px] pb-[20px] lg:py-5 px-5 lg:px-10'>
						<div
							className='flex justify-end my-[30px]'
							data-aos='zoom-in-left'>
							<LazyShareLocationImage
								src={Share}
								alt='Illustration showing a share location icon'
							/>
						</div>
						<div
							className='mt-[60px] mb-[100px]'
							data-aos='zoom-in-right'>
							<LazyOrderImage
								src={Order}
								alt='Ilustration of an Order transaction'
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
