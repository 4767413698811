import React, { useState } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import { BsArrowBarUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../../../slice";
import { createEvent } from "../../../slice";
import { FaSpinner } from "react-icons/fa"

const EventModal = ({ isOpen, onClose, onSave }) => {
  const dispatch = useDispatch();
  const [imageURL, setImageURL] = useState(null);
  const [image, setimage] = useState()
  const loading = useSelector((state) => state.slice.createEventStatus);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const newImageURL = URL.createObjectURL(file);
      setImageURL(newImageURL);
      console.log(newImageURL)
      setimage(file)
      
      return () => URL.revokeObjectURL(newImageURL);
    }
  };
  
  const [event, setEvent] = useState({
    title: "",
    day: "",
    time: "",
    guest: "",
    date: "",
    image: null
  });
  // const allEvents = useSelector(state => state.slice.AllEvents
  // );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {year: 'numeric', month: 'long', day: 'numeric'}
    return date.toLocaleDateString('en-US', options);
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hour = parseInt(hours, 10);
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minutes} ${period}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', event.title);
    formData.append('date', formatDate(event.date));
    formData.append('time', formatTime(event.time));
    formData.append('description', event.title);

    if (image) {
      formData.append('images', image);
    }
    console.log(formData)
    // Dispatch createEvent action
    dispatch(createEvent(formData)).then(() => {
      setEvent({
        title: "",
        day: "",
        time: "",
        guest: "",
        date: "",
        image: null
       })
       setImageURL(null);
        setimage(null);
      onClose()
      dispatch(fetchEvents())
      
     })
     .catch((error) => {
      // Handle any errors that occur during the dispatch
      console.error("Error creating event:", error);
    });
        
  
  }
    
    
    
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-[40%] max-h-[90vh] overflow-auto">
        <div className="flex justify-between items-center">
        <h2 className="text-xl py-1">Create an event</h2>
        <button onClick={onClose} className=" text-xl">
          <LiaTimesSolid />
        </button>
        </div>
        <label htmlFor="cover-image-upload" className="cursor-pointer">
          <div className="px-12 py-16 flex flex-col items-center justify-center border-dashed border-2 rounded-lg my-4 text-primary/50">
            <BsArrowBarUp />
            <p>Upload Image</p>
          </div>
          <input
          name="image"
            id="cover-image-upload"
            type="file"
            className="hidden"
            onChange={handleFileChange}
          />
           {imageURL && <img src={imageURL} alt="Selected preview" />}
        </label>

        <form onSubmit={handleSubmit} className="my-8 flex flex-col gap-4">
          <div>
            <label className="block">Event Name</label>
            <input
              name="title"
              value={event.title}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label>Date</label>
              <input
                name="date"
                type="date"
                value={event.date}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div>
              <label>Time</label>
              <input
                name="time"
                type="time"
                value={event.time}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
          </div>
          <button  type="submit" className="bg-basegreen w-full text-white p-2 rounded-full">
            {/* Create Event */}
            {loading === 'loading' ? <FaSpinner className="animate-spin mx-auto " /> : "Create Event"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EventModal;
