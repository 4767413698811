import React from 'react';
import { Helmet } from 'react-helmet';
import { BlogData, ProductData } from '../../utils/BlogData';
import BlogBox from '../LandingPage/components/BlogBox';
import useScrollTop from '../../custom-hooks/useScrollTop';
import useDisplayBlog from '../../custom-hooks/useDisplayBlog';
import useDisplayProduct from '../../custom-hooks/useDisplayProduct';
import LandingPageLayout from '../../Layouts/LandingLayout';

const Blog = () => {
	const { scrollToTop } = useScrollTop();
	const { displayBlogCount, handleBlogViewMore } = useDisplayBlog();
	const { displayProductCount, handleProductViewMore } = useDisplayProduct();

	return (
		<LandingPageLayout>
			<Helmet>
				<title>
					Ridefraser Blog: Logistics Insights & Product Updates
				</title>
				<meta
					name='description'
					content='Stay up-to-date with the latest logistics news, insights, and product updates from Ridefraser. Learn how we are transforming the e-commerce logistics landscape.'
				/>
				<meta
					name='keywords'
					content='Ridefraser blog, logistics news, e-commerce logistics, product updates, courier services, last-mile delivery'
				/>
				<meta
					property='og:title'
					content='Ridefraser Blog: Logistics Insights & Product Updates'
				/>
				<meta
					property='og:description'
					content='Discover logistics news, tips, and product updates from Ridefraser. Learn how our platform is revolutionizing e-commerce delivery.'
				/>
				<meta
					property='og:type'
					content='website'
				/>
				<meta
					property='og:url'
					content='https://ridefraser.com/blog'
				/>
				<meta
					property='og:image'
					content='https://ridefraser.com/og-blog-image.jpg'
				/>
				<link
					rel='canonical'
					href='https://ridefraser.com/blog'
				/>
			</Helmet>

			<div className='mt-[90px] flex justify-center my-[40px] max-w-full'>
				<div className='w-[370px] md:w-[650px] lg:w-[980px] h-auto flex flex-col py-[20px] px-[10px]'>
					{/* Blog Section */}
					<h1 className='text-primary text-[24px] font-[400] leading-[30px] -tracking-[3%] px-[20px] xl:px-0 my-[10px]'>
						Blog
					</h1>
					<div className='flex flex-col md:flex-row justify-center items-center flex-wrap gap-5'>
						{BlogData.slice(0, displayBlogCount).map((blog) => (
							<BlogBox
								key={blog.id}
								id={blog.id}
								title={blog.title}
								image={blog.image}
								date={blog.date}
								info={blog.info}
								scrollToTop={scrollToTop}
								loading='lazy'
							/>
						))}
					</div>
					{displayBlogCount < BlogData.length && (
						<button
							className='text-basegreen text-center underline underline-offset-4 text-[16px] font-[400] leading-[24px] -tracking-[3%] my-[20px]'
							onClick={handleBlogViewMore}>
							View more
						</button>
					)}

					{/* Product Guides and Updates Section */}
					<h1 className='text-primary text-[24px] font-[400] leading-[30px] px-[20px] lg:px-0 -tracking-[3%] mt-[50px] mb-[0px]'>
						Product Guides and Updates
					</h1>
					<div className='flex flex-col md:flex-row justify-center items-center flex-wrap gap-5'>
						{ProductData.slice(0, displayProductCount).map(
							(product) => (
								<BlogBox
									key={product.id}
									id={product.id}
									title={product.title}
									image={product.image}
									date={product.date}
									info={product.info}
									scrollToTop={scrollToTop}
									loading='lazy'
								/>
							),
						)}
					</div>
					{displayProductCount < ProductData.length && (
						<button
							className='text-basegreen text-center underline underline-offset-4 text-[16px] font-[400] leading-[24px] -tracking-[3%] mt-[20px] mb-[40px]'
							onClick={handleProductViewMore}>
							View more
						</button>
					)}
				</div>
			</div>
		</LandingPageLayout>
	);
};

export default Blog;
