import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import Layout from '../../Layouts/StoreLayout';
import ProductCard from './components/ProductCard';

const StoreFront = ({ storeData }) => {
	const navigate = useNavigate();
	const [cartItems, setCartItems] = useState([]);
	const [wishlist, setWishlist] = useState([]);
	const [openDropdownId, setOpenDropdownId] = useState(null);
	const [activeTab, setActiveTab] = useState('all');
	const [filteredProducts, setFilteredProducts] = useState([]);

	const getAllProducts = useCallback(() => {
		return storeData.categories.flatMap(
			(category) => category.products || [],
		);
	}, [storeData.categories]);

	useEffect(() => {
		setFilteredProducts(getAllProducts());
	}, [getAllProducts, storeData]);

	const handleAddToCart = (product) => {
		const productExists = cartItems.some(
			(selectedProduct) => selectedProduct._id === product._id,
		);

		if (!productExists) {
			setCartItems((prevSelectedProducts) => [
				...prevSelectedProducts,
				{ ...product, value: 1 },
			]);
			toast.info('Product Added to Cart');
		} else {
			setCartItems((prevSelectedProducts) =>
				prevSelectedProducts.map((item) => {
					if (item._id === product._id) {
						return { ...item, value: item.value + 1 };
					}
					return item;
				}),
			);
			toast.info('Product quantity increased');
		}
	};

	const handleCardClick = (product, categorySlug) => {
		navigate(
			`/store/${storeData.storeIdentifier}/categories/${categorySlug}/products/${product.slug}`,
		);
	};

	const handleActionClick = (product, e) => {
		e.stopPropagation();
		setOpenDropdownId(openDropdownId === product.id ? null : product.id);
	};

	const closeDropdown = () => setOpenDropdownId(null);

	const handleView = (product) => {
		navigate(
			`/store/${storeData.storeIdentifier}/products/${product.slug}`,
		);
	};

	const handleShare = (product) => {
		navigator.clipboard.writeText(
			window.location.href +
				`/store/${storeData.storeIdentifier}/products/${product.slug}`,
		);
		toast.success('Product link copied to clipboard!');
	};

	const handleAddToWishlist = (product) => {
		const productExists = wishlist.some((item) => item._id === product._id);
		if (!productExists) {
			setWishlist((prevWishlist) => [...prevWishlist, product]);
			toast.info('Product added to wishlist');
		} else {
			toast.info('Product already in wishlist');
		}
	};

	const onActionClick = (product) => {
		return [
			{ label: 'View Details', onClick: () => handleView(product) },
			{ label: 'Share', onClick: () => handleShare(product) },
			{ label: 'Add to Cart', onClick: () => handleAddToCart(product) },
			{
				label: 'Add to Wishlist',
				onClick: () => handleAddToWishlist(product),
			},
		];
	};

	const handleCategoryClick = (category) => {
		setActiveTab(category.categorySlug);
		setFilteredProducts(category.products || []);
	};

	const handleAllProductsClick = () => {
		setActiveTab('all');
		setFilteredProducts(getAllProducts());
	};

	// Define styles as constants
	const tabStyles = {
		active: {
			backgroundColor: storeData.settings.buttonColor,
			color: 'white',
			// border: `1px solid ${storeData.settings.buttonColor}`,
		},
		inactive: {
			backgroundColor: 'transparent',
			color: 'gray',
			border: '0.5px solid gray',
		},
	};

	return (
		<Layout
			storeName={storeData.storeName}
			storeData={storeData}>
			{/* Tab Navigation */}
			<div className='flex space-x-4'>
				<div className='flex w-3/12 flex-col space-y-4 mb-6 overflow-x-auto bg-white p-4 rounded-xl'>
					<div
						style={
							activeTab === 'all'
								? tabStyles.active
								: tabStyles.inactive
						}
						className='px-4 py-3 rounded-full cursor-pointer'
						onClick={handleAllProductsClick}>
						All Products
					</div>
					{storeData.categories &&
						storeData.categories.map((category) => (
							<div
								key={category._id}
								style={
									activeTab === category.categorySlug
										? tabStyles.active
										: tabStyles.inactive
								}
								className='px-4 py-3 rounded-full'
								onClick={() => handleCategoryClick(category)}>
								{category.name}
							</div>
						))}
				</div>

				{/* Display Filtered Products */}
				<div className='bg-white p-4 rounded-xl w-9/12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-4 overflow-y-auto'>
					{filteredProducts.length > 0 ? (
						filteredProducts.map((product) => (
							<ProductCard
								key={product._id}
								product={product}
								onCardClick={handleCardClick}
								onAddToCart={handleAddToCart}
								onActionClick={onActionClick}
								openDropdownId={openDropdownId}
								handleActionClick={handleActionClick}
								closeDropdown={closeDropdown}
							/>
						))
					) : (
						<p>No products available</p>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default StoreFront;
