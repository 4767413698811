import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/LandingPage/Home';
import About from './pages/LandingPage/About';
import Community from './pages/LandingPage/Community';
import Blog from './pages/LandingPage/Blog';
import BlogDetail from './pages/LandingPage/components/BlogDetail';
import Dashboard from './pages/LandingPage/components/Dashboard';
import Events from './pages/LandingPage/components/Events';
import PrivacyPolicy from './pages/LandingPage/PrivacyPolicy';
import { paths } from './routes';
import { ApiProvider } from './api/api_context';
import StoreContainer from './pages/Container';

const App = () => {
	return (
		<ApiProvider>
			<Router>
				<Routes>
					<Route
						exact
						path={paths.HOME}
						element={<Home />}
					/>
					<Route
						path={paths.ABOUT}
						element={<About />}
					/>
					<Route
						path={paths.COMMUNITY}
						element={<Community />}
					/>
					<Route
						path={paths.BLOG}
						element={<Blog />}
					/>
					<Route
						path={paths.BLOG_DETAIL}
						element={<BlogDetail />}
					/>
					<Route
						path={paths.DASHBOARD}
						element={<Dashboard />}
					/>
					<Route
						path={paths.PRIVACY_POLICY}
						element={<PrivacyPolicy />}
					/>
					<Route
						path={paths.EVENTS}
						element={<Events />}
					/>
					<Route
						path={paths.STORE}
						element={<StoreContainer />}
					/>
				</Routes>
			</Router>
		</ApiProvider>
	);
};

export default App;
