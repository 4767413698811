import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import StoreHeader from '../pages/StoreFront/components/StoreHeader';

const Layout = ({ children, storeData }) => {
	useEffect(() => {
		console.log(storeData);
	}, [storeData]);
	return (
		<div className='flex min-h-screen overflow-hidden h-screen'>
			<main className='flex-1 bg-[#f7f7f7] overflow-hidden'>
				<Helmet>
					<title>{storeData.storeName || 'Store Front'}</title>
					<meta
						name='description'
						content={`Welcome to ${storeData.storeName}`}
					/>
				</Helmet>

				<StoreHeader storeData={storeData} />

				<div className='px-[24px] pt-[12px] overflow-auto'>
					{children}
				</div>
			</main>
		</div>
	);
};

export default Layout;
